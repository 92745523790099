import { FieldState, FormState } from "formstate";
import { action, makeObservable, observable, ObservableMap } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import {
  addMerchantPrefix,
  hasMerchantPrefix,
  removeMerchantPrefix,
  removeNonNumbers,
} from "../../../common";
import {
  businessTypes,
  isBusinessType,
  isBusinessTypeImplemented,
} from "../../../common/constants/business";
import { RecursivePartial } from "../../../types";
import FullScreenSpinner from "../../common/FullScreenSpinner";
import {
  FieldStateFromType,
  FormStateFromType,
  reduceFormState,
} from "./formState";
import { adminUploadData, AdminUpsertDataRequest } from "./requests";
import { AddressSection, createAddressState } from "./Section/AddressSection";
import {
  BankAccountSection,
  createBankAccountState,
} from "./Section/BankAccountSection";
import { AddressDetails, BankingDetails, FormMerchantData } from "./types";
import { isValidIdNumberValidator } from "./utils";

class MerchantCaptureState {
  constructor(private initialState?: RecursivePartial<FormMerchantData>) {}

  merchantId = new FieldState(this.initialState?.merchantId || "").validators(
    (val) => !val && "Merchant name is required"
  );

  firstNames = new FieldState(this.initialState?.firstNames || "").validators(
    (val) => !val && "First names/s is required"
  );

  lastNames = new FieldState(this.initialState?.lastNames || "").validators(
    (val) => !val && "Last name/s is required"
  );

  idNumber = new FieldState(this.initialState?.idNumber || "").validators(
    (val) => isValidIdNumberValidator(val)
  );

  tradingName = new FieldState(this.initialState?.tradingName || "").validators(
    (val) => !val && "Trading Name is required"
  );

  registeredName = new FieldState(
    this.initialState?.registeredName || ""
  ).validators((val) => !val && "Registered Name is required");

  bankingDetails = createBankAccountState(this.initialState?.bankingDetails);

  address = createAddressState(this.initialState?.address);

  businessType = new FieldState(
    this.initialState?.businessType || ""
  ).validators((val) => {
    if (!isBusinessType(val)) {
      return "Select a businessType";
    }
    if (!isBusinessTypeImplemented(val)) {
      return `${val} business type is not implemented`;
    }
    return null;
  });

  autoPopulateMerchantId = new FieldState("").validators();

  // Compose fields into a form
  form = new FormState<FormStateFromType<FormMerchantData>>({
    merchantId: this.merchantId,
    firstNames: this.firstNames,
    lastNames: this.lastNames,
    idNumber: this.idNumber,
    tradingName: this.tradingName,
    registeredName: this.registeredName,
    bankingDetails: this.bankingDetails,
    address: this.address,
    businessType: this.businessType,
  });

  /**
   * Check if a form has changes that have not been saved or validated (isDirty)
   */
  isFormStateDirty = (
    formState:
      | {
          [K: string]: FieldState<any> | FormState<any>;
        }
      | ObservableMap
  ): boolean => {
    let fields;
    if (formState instanceof ObservableMap) {
      fields = Array.from(formState);
    } else {
      fields = Object.entries(formState);
    }
    return fields.some(([_0, field]) => {
      if (field instanceof FieldState) {
        return field.dirty || false;
      } else if (field instanceof FormState) {
        return this.isFormStateDirty(field.$);
      }
      return false;
    });
  };

  copyAddressValues = action(
    (
      from: FieldStateFromType<AddressDetails>,
      to: FieldStateFromType<AddressDetails>
    ) => {
      for (const key in from) {
        const field = key as keyof FieldStateFromType<AddressDetails>;
        to[field].value = from[field].value;
      }
    }
  );

  onSubmit = async (): Promise<
    { success: boolean ; data?: FormMerchantData, error?: "ValidationError" | "SubmissionError" }
  > => {
    // Validate fields
    const res = await this.form.validate();

    // Don't submit if we have errors
    if (res.hasError) {
      // Errors should be displayed on the form as well
      console.error(this.form.error);
      return { success: false, error: "ValidationError" };
    }

    const ids = this.initialState?.ids;

    // "Merchant-" prefix is stripped on auto-populate
    const merchantId = hasMerchantPrefix(this.merchantId.value)
      ? this.merchantId.value
      : addMerchantPrefix(this.merchantId.value);

    // Update data if ids exist
    const requestData: AdminUpsertDataRequest = {
      merchantId: merchantId,
      payload: {
        business: {
          address: reduceFormState<AddressDetails>(this.address),
          registeredName: this.registeredName.value,
          tradingName: this.tradingName.value,
          id: ids && ids.business,
        },
        bankAccounts: [
          {
            ...reduceFormState<BankingDetails>(this.bankingDetails),
            id: ids && ids.bankAccount,
          },
        ],
        stores: [
          {
            address: reduceFormState<AddressDetails>(this.address),
            id: ids && ids.store,
          },
        ],
        persons: [
          {
            isPrincipleOwner: true,
            firstName: this.firstNames.value,
            idNumber: this.idNumber.value,
            lastName: this.lastNames.value,
            residentialAddress: reduceFormState<AddressDetails>(this.address),
            id: ids && ids.person,
          },
        ],
      },
    };

    try {
      await adminUploadData(requestData);
    } catch (e) {
      return { success: false, error: "SubmissionError" };
    }

    // TODO: Standardize input and output
    const outputData: FormMerchantData = {
      merchantId: merchantId,
      bankingDetails: reduceFormState<BankingDetails>(this.bankingDetails),
      address: reduceFormState<AddressDetails>(this.address),
      idNumber: this.idNumber.value,
      firstNames: this.firstNames.value,
      lastNames: this.lastNames.value,
      registeredName: this.registeredName.value,
      tradingName: this.tradingName.value,
      businessType: this.businessType.value,
    };

    return { success: true, data: outputData };
  };
}

const FormHolder = styled.div`
  h4,
  h3 {
    text-decoration: underline;
  }

  form div div {
    display: flex;
    align-items: center;
  }

  label {
    display: block;
  }

  input,
  select,
  label {
    min-width: 200px;
    padding: 1px 2px;
    margin: 1px 2px;
    box-sizing: border-box;
  }

  .inputGroup {
    display: flex;
    align-items: center;
  }
`;

type MerchantCaptureProps = {
  /**
   * Allow for an initial state to be passed in
   */
  initialState?: RecursivePartial<FormMerchantData>;
};

@observer
class MerchantCapture extends React.Component<MerchantCaptureProps, {}> {
  private data: MerchantCaptureState;

  @observable
  private isSubmitting: boolean;

  private statusMessage: string;

  constructor(props: MerchantCaptureProps) {
    super(props);
    this.data = new MerchantCaptureState(props?.initialState);

    makeObservable(this);

    this.isSubmitting = false;
    this.statusMessage = "Submitting";
  }

  async handleSubmit(): Promise<void> {
    this.isSubmitting = true;

    try {
      const result = await this.data.onSubmit();
      if (result.success) {
        alert("Successfully submitted");
      } else {
        // Temporary fix to force reload of data when there is an error
        alert(`Submission error: ${result?.error} Please reload page`);
      }
    } catch (e) {
      // Temporary fix to force reload of data when there is an error
      alert("There was an unknown error. Check logs then reload page");
      console.error(e);
    }

    this.isSubmitting = false;

    return;
  }

  render() {
    const errorStyle: React.CSSProperties = {
      color: "red",
    };

    const data = this.data;

    return (
      <FormHolder>
        <form style={{ padding: "20px 0" }} onSubmit={data.onSubmit}>
          <br />
          <br />
          <h3>Principle Owner</h3>
          <div className="inputGroup">
            {/* TODO: Make options changeable when more are added*/}
            <label htmlFor={`businessType`}>Business Type</label>
            <select
              name={`businessType`}
              value={data.businessType.value}
              disabled={true}
              onChange={(e) => {
                data.businessType.onChange(e.target.value);
              }}
            >
              {businessTypes.map((item: string, i: number) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
              <option value="">Choose One</option>
            </select>
            <p style={errorStyle}>{data.businessType.error}</p>
          </div>
          <div className="inputGroup">
            <label htmlFor="merchantId">Merchant ID</label>
            Merchant-
            <input
              name="merchantId"
              type="text"
              value={removeMerchantPrefix(data.merchantId.value)}
              disabled={true}
              onChange={(e) => {
                data.merchantId.onChange(
                  addMerchantPrefix(removeNonNumbers(e.target.value))
                );
              }}
              placeholder="Numbers only"
            />
            <p style={errorStyle}>{data.merchantId.error}</p>
          </div>
          <div className="inputGroup">
            <label htmlFor="firstNames">First Names</label>
            <input
              name="firstNames"
              type="text"
              value={data.firstNames.value}
              disabled={true}
              onChange={(e) => data.firstNames.onChange(e.target.value)}
            />
            <p style={errorStyle}>{data.firstNames.error}</p>
          </div>
          <div className="inputGroup">
            <label htmlFor="lastNames">Last Names</label>
            <input
              name="lastNames"
              type="text"
              value={data.lastNames.value}
              disabled={true}
              onChange={(e) => data.lastNames.onChange(e.target.value)}
            />
            <p style={errorStyle}>{data.lastNames.error}</p>
          </div>
          <div className="inputGroup">
            <label htmlFor="idNumber">ID Number</label>
            <input
              name="idNumber"
              type="text"
              value={data.idNumber.value}
              disabled={true}
              onChange={(e) => data.idNumber.onChange(e.target.value)}
            />
            <p style={errorStyle}>{data.idNumber.error}</p>
          </div>
          <br />
          <br />
          <h4>Address</h4>
          <AddressSection
            form={this.data.address}
            name="address"
            errorStyle={errorStyle}
          />
          <br />
          <h3>Business details</h3>
          <div className="inputGroup">
            <label htmlFor="idNumber">Registered Name</label>
            <input
              name="idNumber"
              type="text"
              value={data.registeredName.value}
              disabled={true}
              onChange={(e) => data.registeredName.onChange(e.target.value)}
            />
            <p style={errorStyle}>{data.registeredName.error}</p>
          </div>
          <div className="inputGroup">
            <label htmlFor="idNumber">Trading name</label>
            <input
              name="idNumber"
              type="text"
              value={data.tradingName.value}
              disabled={true}
              onChange={(e) => data.tradingName.onChange(e.target.value)}
            />
            <p style={errorStyle}>{data.tradingName.error}</p>
          </div>
          <br />
          <br />
          <BankAccountSection
            form={this.data.bankingDetails}
            errorStyle={errorStyle}
          />
          <br />
          <input
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              this.handleSubmit();
            }}
          />
          <button
            onClick={(e) => {
              e.preventDefault();

              const merchantId = hasMerchantPrefix(data.merchantId.value)
                ? data.merchantId.value
                : addMerchantPrefix(data.merchantId.value);

              // Check if the data in the form has changed
              if (this.data.isFormStateDirty(this.data.form.$)) {
                // If the data has changed, confirm that changes will be lost if not submitted
                const viewResults = window.confirm(
                  "Are you sure you want to view results? All changes will be lost"
                );

                // Return early if the user opts out of the redirect
                if (!viewResults) return;
              }

              window.location.href = `${window.location.origin}/admin/${merchantId}/results`;
            }}
          >
            Results
          </button>
        </form>
        {this.isSubmitting && (
          <FullScreenSpinner message={this.statusMessage} />
        )}
      </FormHolder>
    );
  }
}

export default MerchantCapture;

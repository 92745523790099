/**
 * Helper function to get baked in react variables
 *
 * @see https://create-react-app.dev/docs/adding-custom-environment-variables
 *
 * @param key The key of the environment variable
 * @returns The value of the environment variable
 * @throws Throws an error if this variable does not exist or is empty
 */
export const getEnvValueOrError = (key: string): string => {
  const value = process.env[key];

  if (!value) throw new Error(`Value for '${key}' is not defined`);

  return value;
};

/**
 * @returns The string as an integer
 * @throws Will throw if the input string cannot be converted to a number
 */
export const convertIntStringToIntNumber = (value: string): number => {
  if (!/^\d+$/.test(value)) {
    throw new Error(`Value '${value}' is not an integer string`);
  }

  return parseInt(value);
};

import { Bank } from "../../../common/constants/banking";

export enum STATUS {
  UPLOADED = "UPLOADED",
  UPLOAD_PENDING = "UPLOAD_PENDING",
  APPROVED = "APPROVED",
  PROCESSING_PENDING = "PROCESSING_PENDING",
  PROCESSING = "PROCESSING",
  REJECTED = "REJECTED",
}

export type DataCategories =
  | "PROOF_OF_TRADING_ADDRESS"
  | "ID_NUMBER"
  | "PASSPORT_NUMBER"
  | "BANKING_DETAILS"
  | "BUSINESS_ADDRESS"
  | "FIRST_NAMES"
  | "LAST_NAMES"
  | "TRADING_ADDRESS"
  | "ID_DOCUMENT"
  | "FRAUD_CHECK"
  | "RESIDENTIAL_ADDRESS";

export type Data = {
  category: DataCategories;
  createdAt: string;
  data: string;
  guid: string;
  lastModifiedBy: string;
  modifiedAt: string;
  owner: string;
  status: STATUS;
  reason?: string;
  rawResponses?: object;
};

// Setting this as unknown as we are not implementing it yet
// TODO: Implement document handling
export type Document = unknown;

export type BankingDetails = {
  accountHolder: string;
  accountNumber: string;
  accountType: string;
  bank: Bank | string;
  branchCode: string;
};

export type AddressDetails = {
  addressLine1: string;
  addressLine2: string;
  suburb: string;
  city: string;
  province: string;
  postalCode: string;
};

export type FormMerchantData = {
  merchantId: string;
  firstNames: string;
  lastNames: string;
  idNumber: string;
  /**
   * The doing business name
   */
  tradingName: string;
  /**
   * The legal name of the business
   */
  registeredName: string;
  bankingDetails: BankingDetails;
  address: AddressDetails;
  // TODO: Change strings to enum for values like this and province/bank etc
  businessType: string;
  // TODO: Refactor. Temporary place to store IDs for data
  ids?: {
    person: string;
    store: string;
    business: string;
    bankAccount: string;
  };
};

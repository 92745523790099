import {defaultTheme} from "@ikhokha/react-components";
import {makeObservable, observable} from "mobx";
import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {BsClock, BsClockHistory} from "react-icons/bs";
import {FaRegThumbsUp, FaTimes} from "react-icons/fa";
import ReactJson from "react-json-view";
import styled from "styled-components";
import {RecursivePartial} from "../../../types";
import {
  adminGetData,
  AdminGetDataResponse,
  BitventureIVSResponse,
  DataItem,
  getGroupMerchantVerificationData,
  getMerchantDataItems,
  GroupedMerchantVerificationData,
  IntegrationResult,
  MerchantDataItems,
  Person,
  SolePropBusiness,
  Verification,
} from "../MerchantCapture/requests";
import {STATUS} from "../MerchantCapture/types";
import {DataVerification, FicaTableEntity} from "../../../api";
import {Override} from "../../../components";
import FullScreenSpinner from "../../common/FullScreenSpinner";
import { get } from "lodash";

type MerchantData = AdminGetDataResponse;

const covertIsoStringToCorrectFormat = (isoString: string) => {
  // Ensure the time is in the correct format. This also shifts it to the current time zone
  return new Date(isoString).toLocaleString("en-GB");
};

const upperFirst = (str: string): string => {
  const [firstChar, ...rest] = str.split("");
  return `${firstChar.toUpperCase()}${rest.join("").toLowerCase()}`;
};

const upperSnakeToTitleCase = (str: string): string => {
  const upperSnakeRegex = new RegExp(/^[A-Z_]+[A-Z_]*$/);

  if (upperSnakeRegex.test(str)) {
    return str
      .split("_")
      .map(upperFirst)
      .join(" ");
  }

  return str;
};

type StatusIconProps = {
  status: STATUS;
};

function parseBitVentureIVSResultOrReturnUndefined(
  integrationResult: IntegrationResult
): BitventureIVSResponse | undefined {
  if (typeof integrationResult.rawResponse.data !== "object") {
    return undefined;
  }
  const result = integrationResult.rawResponse.data;
  if (
    typeof (result as RecursivePartial<BitventureIVSResponse>)?.verification
      ?.photo === "string"
  ) {
    return result as BitventureIVSResponse;
  }
  return undefined;
}

// FIXME: We need shared typing with backend so we don't do things like these
function parseExperianAddress(result: IntegrationResult) {
  if (result.integration !== "EXPERIAN") {
    return undefined;
  }
  const typed = result.rawResponse.data as { retData: string };
  try {
    typed.retData = JSON.parse(typed.retData);
    result.rawResponse.data = typed;
    return result;
  } catch (e) {
    return undefined;
  }
}

function openImageNewTab(srcUrl: string) {
  const w = window.open("");
  if (!w) {
    alert("Unable to open image");
    return;
  }
  w.document.write(`<img src="${srcUrl}"/>`);
  w.document.close();
}

// Fixes text content overflow on firefox
const textAreaStyle: React.CSSProperties = {
  width: "100%",
  overflow: "scroll",
};

const StatusIcon: React.FunctionComponent<StatusIconProps> = ({ status }) => {
  let icon: JSX.Element;

  const iconStyle: React.CSSProperties = {
    marginRight: "10px",
    backgroundColor: "white",
    padding: "2px",
    strokeWidth: "2px",
  };

  switch (status) {
    case STATUS.UPLOADED:
      icon = <BsClockHistory color="green" style={iconStyle} />;
      break;
    case STATUS.UPLOAD_PENDING:
      icon = <BsClockHistory color="blue" style={iconStyle} />;
      break;
    case STATUS.PROCESSING_PENDING:
      icon = <BsClockHistory color="darkorange" style={iconStyle} />;
      break;
    case STATUS.PROCESSING:
      icon = <BsClock color="orange" style={iconStyle} />;
      break;
    case STATUS.APPROVED:
      icon = <FaRegThumbsUp color="green" style={iconStyle} />;
      break;
    case STATUS.REJECTED:
      icon = <FaTimes color="red" style={iconStyle} />;
      break;
    default:
      icon = <BsClockHistory color="blue" style={iconStyle} />;
  }

  return (
    <div>
      <p>
        {icon} {upperSnakeToTitleCase(status)}
      </p>
    </div>
  );
};

type ResultTableProps = {
  merchantData: MerchantData;
  refreshHandler: () => void;
};

// TODO: Refactor this table once a design is settled on
// TODO: Make ordering of columns dynamic. Currently hard coded
const ResultTable: React.FunctionComponent<ResultTableProps> = ({
  merchantData,
  refreshHandler,
}) => {
  const columns = [
    "Category",
    "Data",
    "Auto Status",
    "Reason",
    "Raw Response",
    "Response Date",
    "Override Status",
    "Override Reason",
    "Override Details",
  ];

  const FancyTable = styled.table`
    border-collapse: collapse;
    width: 100%;

    td,
    th {
      border: 1px solid #999999;
      text-align: center;
      padding: 8px;
      max-width: 100px;
      width: 100px;
      overflow-x: auto;
    }
  `;

  const HeaderRow: React.FunctionComponent<{ headings: string[] }> = ({
    headings,
  }) => (
    <tr>
      {headings.map((col) => (
        <th key={col}>{col}</th>
      ))}
    </tr>
  );

  const SectionRow: React.FunctionComponent<{ text?: string }> = ({
    text = "",
  }) => (
    <tr>
      <td
        colSpan={9}
        style={{
          backgroundColor: defaultTheme.colors.primary.yellow,
          textAlign: "center",
        }}
      >
        {text}
      </td>
    </tr>
  );

  const TitleRow: React.FunctionComponent<{
    text: string;
    align?: "center" | "left";
    color?: React.CSSProperties["color"];
  }> = ({
    text,
    align = "left",
    color = defaultTheme.colors.primary.yellow,
  }) => (
    <tr>
      <td colSpan={9} style={{ textAlign: align, backgroundColor: color }}>
        {text}
      </td>
    </tr>
  );

  const DisplayVerification: React.FunctionComponent<{
    verification: Verification;
  }> = ({ verification }) => {
    const [imageSource, setPhoto] = useState<string>("");
    const [isPhotoValid, setValidPhoto] = useState<boolean>(false);

    useEffect(() => {
      async function isBase64UrlImage(base64String: string): Promise<boolean> {
        let image = new Image();
        image.src = base64String;
        return await new Promise((resolve) => {
          image.onload = function() {
            if (image.height === 0 || image.width === 0) {
              resolve(false);
              return;
            }
            resolve(true);
          };
          image.onerror = () => {
            resolve(false);
          };
        });
      }
      const bitVentureIVSResponse = parseBitVentureIVSResultOrReturnUndefined(
        verification.integrationResult
      );
      if (bitVentureIVSResponse) {
        (async () => {
          if (bitVentureIVSResponse) {
            const imageSrc = `data:image/jpeg;base64,${bitVentureIVSResponse.verification.photo}`;
            const isValidPhoto = await isBase64UrlImage(imageSrc);
            bitVentureIVSResponse.verification.photo = "See image below";
            setValidPhoto(isValidPhoto);
            setPhoto(imageSrc);
          } else {
            setPhoto("");
            setValidPhoto(false);
          }
        })();
      }
    }, [verification.integrationResult]);
    const bitVentureIVSResponse = parseBitVentureIVSResultOrReturnUndefined(
      verification.integrationResult
    );

    let verificationCopy = { ...verification };
    let bitVentureIVSResponseCopy = { ...bitVentureIVSResponse };

    if (bitVentureIVSResponseCopy.verification) {
      verificationCopy.integrationResult.rawResponse.data = bitVentureIVSResponseCopy;
    }
    verificationCopy.integrationResult =
      parseExperianAddress(verificationCopy.integrationResult) ??
      verificationCopy.integrationResult;

    if (bitVentureIVSResponse) {
      return (
        <div>
          <ResultsModal verification={verificationCopy} />
          {isPhotoValid ? (
            <button onClick={() => openImageNewTab(imageSource)}>
              <img
                src={imageSource}
                alt="ID Verification"
                style={{ width: "90%" }}
              />
            </button>
          ) : (
            <p>Invalid image</p>
          )}
        </div>
      );
    }
    return (
      <div>
        <ResultsModal verification={verificationCopy} />
      </div>
    );
  };

  const StyledModal = styled.div<{ display: string }>`
    hr {
      border: 1px solid #ddd;
      margin-bottom: 0;
      margin-top: 0;
    }

    .header {
      padding-left: 10px;
      text-align: start;
    }
    .footer {
      padding: 15px;
      text-align: end;
    }
    .footer > button {
      margin-left: 10px;
    }
    .modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
    }
    .modal-main {
      position: fixed;
      background: white;
      width: 45%;
      height: 85%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 10px 0;
      text-align: left;
      border-radius: 10px;
    }
    .modal-content {
      padding: 10px;
      overflow-y: scroll;
      height: 85%;
    }
    .display-modal {
      display: ${(props) => props.display}
    }
    }`;

  const ResultsModal: React.FunctionComponent<{
    verification: Verification;
  }> = ({ verification }) => {
    const body = document.querySelector("body");

    const [show, setShow] = useState(false);
    const handleShow = () => {
      if (body) {
        // Prevent page from scrolling when modal is open
        body.style.overflow = "hidden";
      }
      setShow(true);
    };
    const handleClose = () => {
      if (body) {
        body.style.overflow = "auto";
      }
      setShow(false);
    };
    return (
      <div>
        <button type="button" onClick={handleShow}>
          Show Results
        </button>
        <StyledModal display={show ? "block" : "none"}>
          <div className="modal display-modal">
            <div className="modal-main">
              <div className="header">
                <h3>{verification.type}</h3>
              </div>
              <hr />
              <div className="modal-content">
                {show && (
                  <div>
                    <ReactJson
                      collapsed={false}
                      name={false}
                      src={verification}
                      displayDataTypes={false}
                      style={{
                        backgroundColor: "white",
                      }}
                    />
                  </div>
                )}
              </div>
              <hr />
              <div className="footer">
                <button type="button" onClick={handleClose}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </StyledModal>
      </div>
    );
  };

  const VerificationRow: React.FunctionComponent<{
    index: number;
    verification: Verification;
    entity: FicaTableEntity;
  }> = ({ index, verification, entity }) => {

    /**
     * Hack: this seemed less involved than adding the IDType to a person
     * Also doesn't feel like the IDType belong to the Person object on FICA
     */
    let dataItem = null 
    if (verification.type === DataVerification.DOCUMENT) {
      dataItem = {
        Person: {
          data: {
            idNumber: get(verification.data, "Person.data.idNumber"),
            idType: get(verification.integrationResult.rawResponse, "IDType")
          }
        }
      }
    }
    return <React.Fragment>
      <tr
      style={{
        backgroundColor: index % 2 === 0 ? "white" : "#dddddd",
      }}
    >
      <td>
        <textarea
          readOnly={true}
          cols={30}
          rows={10}
          style={textAreaStyle}
          value={JSON.stringify( dataItem ?? verification.data, null, 4)}
        ></textarea>
      </td>
      <td style={{ textAlign: "center" }}>
        <StatusIcon status={verification.status} />
      </td>
      <td>{verification.reason}</td>
      <td>
        <DisplayVerification verification={verification} />
      </td>
      <td>{covertIsoStringToCorrectFormat(verification.createdAt)}</td>
      <td>
        {verification.override?.status}
        {!verification.override && !(verification.type === DataVerification.ADDRESS && entity === FicaTableEntity.PERSON) ? (
          <Override
            type={verification.type}
            owner={verification.owner}
            entityId={verification.entityId}
            id={verification.id}
            status={verification.status}
            entityType={entity}
            refresh={refreshHandler}
          />
        ): <></>}
      </td>
      <td>{verification.override?.reason}</td>
      <td>{verification.override?.comment}</td>
    </tr>
    </React.Fragment>
  }

  // move category name to the top of the td
  // center alignment requires scrolling through checks before category is visible
  const alignCategoryText: React.CSSProperties = {
    verticalAlign: "top",
    paddingTop: "100px",
  };

  const PrettyMainCategory: Record<DataVerification, string> = {
    BankingDetails: "Banking Details",
    MATCH: "MATCH",
    SAFPS: "SAFPS",
    Sanctions: "Sanctions",
    Identity: "ID Verification",
    Address: "Address",
    Selfie: "Selfie Verification",
    DocumentVerification: "ID Document Verification"
  };

  // Split sections into components for more manageability
  type PersonData = Pick<
    GroupedMerchantVerificationData,
    | DataVerification.IDENTITY
    | DataVerification.MATCH
    | DataVerification.SAFPS
    | DataVerification.SANCTIONS
    | DataVerification.ADDRESS
    | DataVerification.SELFIE
    | DataVerification.DOCUMENT
  >;

  type BankAccountData = Pick<
    GroupedMerchantVerificationData,
    DataVerification.BANKING_DETAILS
  >;

  type BusinessData = Pick<
    GroupedMerchantVerificationData,
    DataVerification.ADDRESS
  >;

  const BusinessSection: React.FunctionComponent<{
    data: BusinessData;
    item?: DataItem;
  }> = ({ data, item }) => {
    const businessItem = item?.data as SolePropBusiness; // FIXME
    return (
      <React.Fragment key="business">
        <SectionRow text="Business Information" />
        <TitleRow
          text={`${businessItem?.registeredName ?? "Business "} verification results`}
          color={lightYellow}
        />
        {Object.keys(data).map((key, i) => {
          let field = key as keyof BusinessData;
          const verification: Verification = data[field] && data[field][0];
          return (
            <React.Fragment key={field}>
              <tr>
                <td style={verification ? alignCategoryText : {}}>
                  {PrettyMainCategory[field]}
                </td>
                <td colSpan={9} style={{ padding: "0", border: "none" }}>
                  <FancyTable>
                    {!verification ? (
                      <tr>
                        <td colSpan={9} style={{ textAlign: "left" }}>
                          No results yet for {field}
                        </td>
                      </tr>
                    ) : (
                      <VerificationRow
                        key={`${field}-${i}`}
                        verification={verification}
                        index={i}
                        entity={FicaTableEntity.BUSINESS}
                      />
                    )}
                  </FancyTable>
                </td>
              </tr>
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  };

  const PersonSection: React.FunctionComponent<{
    data: PersonData;
    item?: DataItem;
  }> = ({ data, item }) => {
    const dataItem = item?.data as Person;
    return (
      <React.Fragment key="person-section">
        <SectionRow text="Person" />
        <TitleRow
          text={`${dataItem?.firstName} ${dataItem?.lastName} verification results`}
          color={lightYellow}
        />
        {Object.keys(data).map((key, i) => {
          let field = key as keyof PersonData;
          const verification: Verification = data[field] && data[field][0];
          return (
            <React.Fragment key={field}>
              <tr>
                <td style={verification ? alignCategoryText : {}}>
                  {PrettyMainCategory[field]}
                </td>
                <td colSpan={9} style={{ padding: "0", border: "none" }}>
                  <FancyTable>
                    {!verification ? (
                      <tr>
                        <td colSpan={9} style={{ textAlign: "left" }}>
                          No results yet for {field}
                        </td>
                      </tr>
                    ) : (
                      <VerificationRow
                        key={`${field}-${i}`}
                        verification={verification}
                        index={i}
                        entity={FicaTableEntity.PERSON}
                      />
                    )}
                  </FancyTable>
                </td>
              </tr>
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  };

  const BankAccountSection: React.FunctionComponent<{
    data: BankAccountData;
    item?: DataItem;
  }> = ({ data, item }) => {
    const field = DataVerification.BANKING_DETAILS;
    const verification: Verification = data[field] && data[field][0];
    return (
      <React.Fragment key={field}>
        <SectionRow text="Bank Accounts" />
        <TitleRow
          text={`${PrettyMainCategory[field]} verification results`}
          color={lightYellow}
        />
        <tr>
          <td style={verification ? alignCategoryText : {}}>
            {PrettyMainCategory[field]}
          </td>
          <td colSpan={9} style={{ padding: "0", border: "none" }}>
            <FancyTable>
              {!verification ? (
                <tr>
                  <td colSpan={9} style={{ textAlign: "left" }}>
                    No results yet for {field}
                  </td>
                </tr>
              ) : (
                <VerificationRow
                  key={`${field}-${1}`}
                  verification={verification}
                  index={1}
                  entity={FicaTableEntity.BANKACCOUNT}
                />
              )}
            </FancyTable>
          </td>
        </tr>
      </React.Fragment>
    );
  };

  const ResultsTable: React.FunctionComponent<{
    verifications: GroupedMerchantVerificationData;
    items: MerchantDataItems;
  }> = ({ verifications, items }) => {
    const { Sanctions, SAFPS, BankingDetails, DocumentVerification, Identity, Selfie } = verifications;
    const persons: PersonData = {
      Sanctions,
      MATCH: verifications.MATCH?.filter((verificationItem) => { return verificationItem.entityId === items.persons.id }),
      SAFPS,
      Identity,
      Address: verifications.Address?.filter((verificationItem) => { return verificationItem.entityId === items.persons.id }),
      Selfie,
      DocumentVerification
    };

    const bankAccounts: BankAccountData = {
      BankingDetails,
    };

    const businessData: BusinessData = {
      Address: verifications.Address?.filter((verificationItem) => { return verificationItem.entityId === items.business.id }),
    };
    return (
      <React.Fragment key="results-table-section">
        <BusinessSection data={businessData} item={items.business} />
        <PersonSection data={persons} item={items.persons} />
        <BankAccountSection data={bankAccounts} item={items.bankAccounts} />
      </React.Fragment>
    );
  };

  const groupedVerifications = getGroupMerchantVerificationData(merchantData);
  const merchantDataItems = getMerchantDataItems(merchantData);

  // Divider styling
  const lightYellow = "##ffea92";

  return (
    <FancyTable>
      <HeaderRow headings={columns} />
      <ResultsTable
        verifications={groupedVerifications}
        items={merchantDataItems}
      />
    </FancyTable>
  );
};

type MerchantResultsProps = {
  merchantId: string;
};

@observer
class MerchantResults extends React.Component<MerchantResultsProps> {
  merchantId: string;

  @observable
  loadingData: boolean = false;

  @observable
  updatingData: boolean = false;

  @observable
  merchantData: MerchantData | null = null;

  constructor(props: MerchantResultsProps) {
    super(props);

    const { merchantId } = props;

    this.merchantId = merchantId;

    this.refreshHandler = this.refreshHandler.bind(this);

    this.loadMerchantData();
    makeObservable(this);
  }

  // this function handles updates from override
  // this is a "soft-refresh", a bit of a hack really
  async refreshHandler() {
    this.updatingData = true;
    const result = await adminGetData(this.merchantId);
    this.merchantData = result;
    this.updatingData = false;
  }

  async loadMerchantData(): Promise<void> {
    this.loadingData = true;

    // Merchant-99999 can return real data. Rest we will return an empty object

    const result = await adminGetData(this.merchantId);

    // FIXME: Currently the system allows duplicates
    //  We need to handle it in a better way
    this.merchantData = result;
    this.loadingData = false;
  }

  async refreshMerchantData(): Promise<void> {
    this.merchantData = null;
    await this.loadMerchantData();
  }

  render() {
    const buttonStyles = {
      fontSize: "16px",
      padding: "5px",
      marginBottom: "10px",
      marginRight: "5px",
    };

    return (
      <React.Fragment>
        <h3>Showing results for "{this.merchantId}"</h3>
        <button
          style={buttonStyles}
          onClick={() => {
            window.location.href = `${window.location.origin}/admin/${this.merchantId}`;
          }}
        >
          View Merchant Information
        </button>
        <button style={buttonStyles} onClick={() => this.refreshMerchantData()}>
          Refresh data
        </button>
        {this.merchantData ? (
          <ResultTable
            key="result-table"
            merchantData={this.merchantData}
            refreshHandler={this.refreshHandler}
          />
        ) : this.loadingData ? (
          <p>Loading</p>
        ) : (
          <p>No results yet</p>
        )}
        {this.updatingData && <FullScreenSpinner message="Updating results" />}
      </React.Fragment>
    );
  }
}

export default MerchantResults;

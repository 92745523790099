import { DataVerification } from "../../../api";
import { $axios } from "../../../common";
import { accountTypeMap, Bank, ficaToProfileBankMap } from "../../../common/constants/banking";
import { BusinessType } from "../../../common/constants/business";
import { DataCategories, FormMerchantData, STATUS } from "./types";

type Store = {
  address: Address;
};

export type Person = {
  idNumber: string;
  firstName: string;
  lastName: string;
  residentialAddress: Address;
  isPrincipleOwner: boolean;
};

type BankAccount = {
  accountNumber: string;
  accountHolder: string;
  accountType: string;
  bank: string;
  branchCode: string;
};

export type SolePropBusiness = {
  address: Address;
  phoneNumber?: string;
  tradingName: string;
  registeredName: string;
};

type HasOptionalId<T> = T & { id?: string };

export type SolePropData = {
  business: HasOptionalId<SolePropBusiness>;
  persons: Array<HasOptionalId<Person>>;
  stores: Array<HasOptionalId<Store>>;
  bankAccounts: Array<HasOptionalId<BankAccount>>;
};

// TODO: Implement error handling
// TODO: Implement typesafe api routing/resources

function handleNetworkError(error: unknown, reject: (reason?: any) => void) {
  console.error("Error", JSON.stringify(error));
  alert(`There was a network error. Check logs: ${JSON.stringify(error)}`);
  reject(error);
}

export type Integrations =
  | "BITVENTURE"
  | "MASTERCARD"
  | "EXPERIAN"
  | "THIS_IS_ME";

export type IntegrationResult = {
  integration: Integrations;
  successful: boolean;
  rawResponse: { data: object };
};

export type BitventureIVSResponse = {
  verification: {
    name: string;
    surname: string;
    photo: string;
    identityNumber: string;
    smartCardIssued: string;
    idIssueDate: string;
    idSequenceNumber: string;
    idNumberBlocked: string;
    deceasedStatus: string;
    dateOfDeath: string;
    maritalStatus: string;
    dateOfMarriage: string;
    onHANIS: string;
    onNPR: string;
    countryOfBirth: string;
    hanisReference: string;
    responseCode: string;
    responseError: string;
    responseMessage: string;
  };
};

export type Verification = {
  /**
   * An optional reason why data is rejected or could not be verified.
   */
  reason?: string;
  status: STATUS;
  // TODO: Add typing. The data given to the verification method
  data: object;
  category: DataCategories;
  integrationResult: IntegrationResult;
  modifiedAt: string;
  createdAt: string;
  owner: string;
  id: string;
  type: DataVerification;
  entityId: string;
  override?: Override;
};

type Override = {
  reason: string;
  userId: string;
  status: STATUS;
  comment: string;
  modifiedAt: string;
  entityId: string;
};

export type ReturnedData<D> = {
  owner: string;
  status: STATUS;
  /** ISO TimeStamp */
  modifiedAt: string;
  /** ISO TimeStamp */
  createdAt: string;
  /** v4 Guid */
  id: string;
  data: D;
  documents: Array<Document>;
  verifications: Array<Verification>;
  reason?: string;
};

export type AdminGetDataResponse = {
  business: ReturnedData<SolePropBusiness>;
  persons: ReturnedData<Person>[];
  stores: ReturnedData<Store>[];
  bankAccounts: ReturnedData<BankAccount>[];
};

export type DataItem<D = object> = Omit<
  ReturnedData<D>,
  "verifications" | "documents"
>;

export type GroupedMerchantVerificationData = Record<
  DataVerification,
  Verification[]
>;

export type MerchantDataItems = Record<keyof AdminGetDataResponse, DataItem>;
// FIXME: this function might be a bit redundant

export function getMerchantDataItems(
  data: AdminGetDataResponse | null
): MerchantDataItems {
  let result: MerchantDataItems = {} as MerchantDataItems;
  for (const x in data) {
    const key = x as keyof AdminGetDataResponse;
    let parentItem: DataItem;
    switch (key) {
      case "business":
        parentItem = data[key];
        break;
      case "bankAccounts":
      case "persons":
      case "stores":
        parentItem = data[key][0];
        break;
    }
    if (parentItem) {
      result = {
        ...result,
        [key]: { ...parentItem, verifications: undefined },
      };
    }
  }
  return result;
}

// TODO: Need to find an smart way to extract addresses
export function getGroupMerchantVerificationData(
  data: AdminGetDataResponse | null
): GroupedMerchantVerificationData {
  let result: GroupedMerchantVerificationData = {} as GroupedMerchantVerificationData;
  for (const x in data) {
    const key = x as keyof AdminGetDataResponse;
    let verifications: Verification[];
    switch (key) {
      case "business":
        verifications = data[key] ? data[key].verifications : [];

        break;
      case "bankAccounts":
        verifications = data[key][0]?.verifications ?? [];
        break;
      case "persons":
        verifications = data[key][0]?.verifications ?? [];
        break;
      case "stores":
        verifications = data[key][0]?.verifications ?? [];
        break;
    }
    const sortedVerifications = verifications.sort((a, b) => {
      return a.createdAt < b.createdAt ? 1 : -1;
    });
    const objWithVerificationTypesAsKeys = sortedVerifications.reduce(function(prev, curr) {
      prev[curr.type] = prev[curr.type] || [];
      prev[curr.type].push(curr);
      return prev;
    }, result);
    
    result = {
      ...objWithVerificationTypesAsKeys
    };
  }
  return result;
}

export async function adminGetData(
  merchantId: string
): Promise<AdminGetDataResponse | null> {
  return new Promise((resolve, reject) => {
    $axios
      .get(`/admin/owners/${merchantId}`)
      .then(({ status, data }) => {
        return status === 404 ? resolve(null) : resolve(data);
      })
      .catch((err) => {
        handleNetworkError(err, reject);
      });
  });
}

export async function getFicaData(
  merchantId: string
): Promise<Partial<FormMerchantData>> {
  const response = await adminGetData(merchantId);
  if (!response) {
    alert(`FICA data not found for the given owner: ${merchantId}`);
    return {};
  }

  let ids:
    | {
        bankAccount: string;
        business: string;
        person: string;
        store: string;
      }
    | undefined;

  // Check if the returned data has too many entities. We expect one of each
  if (
    response.persons.length > 1 ||
    response.bankAccounts.length > 1
  ) {
    alert(
      "Received data has more than one id per entity type. Please contact Developer Support"
    );
    console.error(JSON.stringify({ response }));
    throw new Error("One or more data types has more than one entity");
    // Only include the Ids for an item when they are all present
    // Then set the value
  } else if (
    response.persons[0]?.id &&
    response.stores[0]?.id &&
    response.bankAccounts[0]?.id &&
    response.business?.id
  ) {
    ids = {
      bankAccount: response.bankAccounts[0].id,
      business: response.business.id,
      person: response.persons[0].id,
      store: response.stores[0].id,
    };
  } else if (
    response.persons[0]?.id ||
    response.stores[0]?.id ||
    response.bankAccounts[0]?.id ||
    response.business.id
  ) {
    ids = {
      bankAccount: response.bankAccounts[0]?.id,
      business: response.business?.id,
      person: response.persons[0]?.id,
      store: response.stores[0]?.id,
    };

    const accountType = response.bankAccounts[0].data.accountType
    const knownAccountType = accountTypeMap[accountType]
    response.bankAccounts[0].data.accountType = knownAccountType ?? response.bankAccounts[0].data.accountType

    return {
      merchantId,
      address: response.business?.data.address,
      registeredName: response.business?.data.registeredName,
      tradingName: response.business?.data.tradingName,
      businessType: BusinessType.SOLE_PROPRIETOR,
      bankingDetails: response.bankAccounts[0]?.data,
      firstNames: response.persons[0]?.data.firstName,
      idNumber: response.persons[0]?.data.idNumber,
      lastNames: response.persons[0]?.data.lastName,
      ids: ids,
    };
  }

  // expecting legacy bank format from backend
  let bank = response.bankAccounts[0].data.bank;
  const ficaBank = ficaToProfileBankMap[bank];
  // merchant server bankId's mapped to profile service bank names (comment from migration)
  const merchantBank = Bank[bank as Bank];
  if (!ficaBank && !merchantBank) {
    alert(`Unexpected bank name: ${bank}`);
    throw new Error(`Unexpected bank name: ${bank}`);
  }
  response.bankAccounts[0].data.bank = ficaBank ?? merchantBank;

  // account types
  const accountType = response.bankAccounts[0].data.accountType
  const knownAccountType = accountTypeMap[accountType]
  response.bankAccounts[0].data.accountType = knownAccountType ?? response.bankAccounts[0].data.accountType
  return {
    merchantId,
    address: response.business.data.address,
    registeredName: response.business.data.registeredName,
    tradingName: response.business.data.tradingName,
    businessType: BusinessType.SOLE_PROPRIETOR,
    bankingDetails: response.bankAccounts[0].data,
    firstNames: response.persons[0].data.firstName,
    idNumber: response.persons[0].data.idNumber,
    lastNames: response.persons[0].data.lastName,
    ids: ids,
  };
}

type Address = {
  addressLine1: string;
  addressLine2: string;
  suburb: string;
  city: string;
  province: string;
  postalCode: string;
};

type AdminUploadDataResponse = {
  owner: string;
  bankAccount: BankAccount[];
  business: {
    // contactNumber not implemented
    contactNumber: "";
    registeredName: string;
    address: Address;
    tradingName: string;
  };
  // FIXME: Should be persons and stores?
  person: Person[];
  store: Store[];
};

export type AdminUpsertDataRequest = {
  merchantId: string;
  payload: SolePropData;
};

export async function adminUploadData(
  request: AdminUpsertDataRequest
): Promise<AdminUploadDataResponse> {
  const { merchantId, payload } = request;

  const result = await new Promise<AdminUploadDataResponse>(
    (resolve, reject) => {
      $axios
        .post(`/admin/owners/${merchantId}/verify-data`, payload)
        .then(({ data }) => {
          console.log("Admin Create Data: Data", { data });
          // FIXME: Check the data fix the expected response here
          resolve(data);
        })
        .catch((error) => {
          handleNetworkError(error, reject);
        });
    }
  );

  return result;
}

import { Validator } from "formstate";

export const isValidIdNumberValidator: Validator<string> = (
  idNumber: string
) => {
  // check that value submitted is a number
  if (isNaN(parseInt(idNumber))) {
    return "Value supplied is not a valid number";
  }

  // check length of 13 digits
  if (idNumber.length !== 13) {
    return "Number supplied does not have 13 digits";
  }

  // check that YYMMDD group is a valid date
  let yy = parseInt(idNumber.substring(0, 2)),
    mm = parseInt(idNumber.substring(2, 4)),
    dd = parseInt(idNumber.substring(4, 6));

  const dob = new Date(yy, mm - 1, dd);

  // check values - add one to month because Date() uses 0-11 for months
  if (
    !(
      (dob.getFullYear() + "").substring(2, 4) ===
        yy.toString().padStart(2, "0") &&
      dob.getMonth() === mm - 1 &&
      dob.getDate() === dd
    )
  ) {
    return "Date in first 6 digits is invalid. Should be a date";
  }

  // ensure third to last digit is a 1 or a 0
  if (parseInt(idNumber.substring(10, 11)) > 1) {
    return (
      "Third to last digit can only be a 0 or 1 but is a " +
      idNumber.substring(10, 11)
    );
  }

  // ensure second to last digit is a 8 or a 9
  if (parseInt(idNumber.substring(11, 12)) < 8) {
    return (
      "Second to last digit can only be a 8 or 9 but is a " +
      idNumber.substring(11, 12)
    );
  }

  // calculate check bit (Z) using the Luhn algorithm
  var ncheck = 0,
    beven = false;

  for (var c = idNumber.length - 1; c >= 0; c--) {
    var cdigit = idNumber.charAt(c),
      ndigit = parseInt(cdigit, 10);

    if (beven) {
      if ((ndigit *= 2) > 9) ndigit -= 9;
    }

    ncheck += ndigit;
    beven = !beven;
  }

  if (ncheck % 10 !== 0) {
    return "Invalid ID. Checksum is incorrect";
  }

  return "";
};

/**
 * Make the first letter uppercase of the give string
 */
export const titleCase = (str: string): string => {
  const [firstChar, ...rest] = str.split("");
  return `${firstChar.toUpperCase()}${rest.join("")}`;
};

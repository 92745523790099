import axios, { AxiosRequestConfig } from "axios";
import { Auth } from "aws-amplify";
import { getEnvValueOrError } from "./utils";
import { SessionCredentials } from "./types";

let baseApiUrl = getEnvValueOrError("REACT_APP_IK_SERVICES_BASE_URL");

const ficaServiceApi = /localhost/.test(baseApiUrl)
  ? `${baseApiUrl}/playpen/v1`
  : `${baseApiUrl}/fica/v1`;

axios.defaults.baseURL = ficaServiceApi;

axios.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    config.headers["X-Auth-Token"] =
      "4b8212d8a23c567938eccc98dd53ae64c48381f46e5afb97f6b8d720a0d9d080";
    config.headers["Content-Type"] = "application/json";
    const session = await getSession();

    // none of the fica-service endpoints are anonymous
    if (session.error) {
      return Promise.reject(session.error);
    }
    if (session.data) {
      config.headers["Authorization"] = `Bearer ${session.data.id}`;
    }
    if (config.validateStatus) {
      config.validateStatus = (status: number) =>
        status === 200 || status === 404;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const getSession = async (): Promise<{
  data?: SessionCredentials;
  error?: Error;
}> => {
  try {
    const session = await Auth.currentSession();
    if (session.isValid()) {
      return {
        data: {
          id: session.getIdToken().getJwtToken(),
          accessToken: session.getAccessToken().getJwtToken(),
          refreshToken: session.getRefreshToken().getToken(),
          success: true,
        },
      };
    }
    return {
      error: new Error("Session is not longer valid"),
    };
  } catch (e) {
    console.error(e);
    return {
      error: new Error(JSON.stringify(e)),
    };
  }
};

export const $axios = axios;
